/* eslint-disable import/first */
import './polyfills'
import './styles/global.css'

import { initGA, setAutoLinker } from '@nobia/zeus-components/lib/analytics'
import { PortalProvider } from '@nobia/zeus-components/lib/portal'
import Root from '@nobia/zeus-components/lib/root'
import { registerServiceWorker } from '@nobia/zeus-components/lib/serviceWorker'
import settings from '@nobia/zeus-components/lib/settings'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './app'

if (settings.analytics && settings.analytics.id) {
  initGA(settings.analytics.id, false, { allowLinker: true })
  setAutoLinker(settings.analytics.domains)
}

const cacheConfig = {
  typePolicies: {
    Customer: {
      fields: {
        messages: {
          keyArgs: false,
          merge(existing = [], incoming, { args: { skip } }) {
            if (!skip) {
              return [...incoming]
            }
            return [...existing, ...incoming]
          },
        },
      },
    },
  },
}

const renderApp = () => {
  ReactDOM.render(
    <Root
      apiUrl={settings.gatewayAPI}
      brand={settings.brand}
      basename={settings.basename}
      cacheConfig={cacheConfig}
    >
      <PortalProvider>
        <App />
      </PortalProvider>
    </Root>,
    document.getElementById('root')
  )
}

renderApp()
registerServiceWorker()

// Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./app', renderApp)
}
