import styled from 'styled-components'

const FormItem = styled.div`
  margin-top: 40px;
`

const UploadFormItem = styled(FormItem)`
  position: relative;
  border: 1px solid #e0e4f1;
  border-radius: 5px;
  margin-top: 0px;
  padding: 20px;
  min-height: 90px;
  > div > div {
    border: none;
    padding: 0;
    margin: 0;
    min-height: 28px;
    > div {
      padding: 0;
      margin-right: 0;
      margin-left: auto;
      label {
        padding: 0px 20px 0px 20px;
        text-transform: ${p => p.theme.smallButton.textTransform};
        font-size: ${p => p.theme.smallButton.fontSize};
        width: max-content;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${p => p.theme.smallButton.height};
      }
      label:before {
        border-radius: ${p => p.theme.smallButton.borderRadius};
        height: ${p => p.theme.smallButton.height};
      }
    }
  }
  > div > div:nth-child(2) {
    margin-top: 15px;
  }
  > div > div:nth-child(3) {
    margin-top: 30px;
    > div > div {
      margin-bottom: 5px;
    }
  }
`

export default UploadFormItem
