import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const icon = (
  <svg
    width="11px"
    height="8px"
    viewBox="0 0 11 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon
      id="Triangle-3"
      fill="#000000"
      points="5.5625 7.11853966 0.583984375 0.225210337 10.5410156 0.225210337"
    />
  </svg>
)

const SelectWrapper = styled.div`
  font-size: 14px;
  position: relative;
  min-width: 100px;

  opacity: ${p => !!p.disabled && 0.6};

  > svg {
    position: absolute;
    top: 18px;
    right: 18px;
    z-index: 5;
    pointer-events: none;
  }
`
const StyledSelect = styled.select`
  appearance: none;
  position: relative;
  width: 100%;
  min-width: 100px;
  border-radius: ${p => p.theme.forms.borderRadius};
  border: ${p => p.theme.forms.border};
  height: ${p => p.theme.forms.height};
  padding: ${p => p.theme.forms.padding};
  padding-bottom: 0;
  padding-top: 0;
  font-family: ${p => p.theme.forms.fontFamily};
  font-size: ${p => p.theme.forms.fontSize};
  font-weight: ${p => p.theme.forms.fontWeight};
  height: ${p => p.theme.forms.height};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: ${p => p.theme.forms.height};
  color: ${p => p.theme.forms.colors.color};
  background-color: ${p => p.theme.forms.colors.background};
  z-index: 1;

  ${p =>
    !!p.active &&
    css`
      border: 1px solid ${p.theme.forms.colors.activeBorder};
      color: ${p.theme.forms.colors.active};
    `};

  ${p => p.theme.media.min.sm`
    font-size: ${p.theme.forms.fontSizeDesktop};
  `};

  ::-ms-expand {
    display: none;
  }

  > option {
    font-weight: ${p => p.theme.forms.fontWeight};
  }
`

const Select = ({
  onChange,
  onBlur,
  value,
  label,
  options,
  disabled,
  active,
  hideLabel,
  ...rest
}) => {
  const [focused, setFocused] = useState(active)

  const change = useCallback(
    e => {
      onChange(e)
    },
    [onChange]
  )
  const blur = useCallback(
    e => {
      setFocused(false)
      onBlur(e)
    },
    [onBlur]
  )

  return (
    <SelectWrapper disabled={disabled}>
      <StyledSelect
        active={focused}
        value={value}
        options={options}
        onChange={change}
        onBlur={blur}
        onFocus={() => setFocused(true)}
        disabled={disabled}
        {...rest}
      >
        {!!label && (
          <option
            disabled
            value=""
            hidden={hideLabel}
            selected={value == null ? 'selected' : null}
          >
            {label}
          </option>
        )}
        {options.map(
          ({ label: optionLabel, value: optionValue, ...props }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <option key={index} value={optionValue} {...props}>
              {optionLabel}
            </option>
          )
        )}
      </StyledSelect>
      {icon}
    </SelectWrapper>
  )
}

Select.defaultProps = {
  onBlur: () => {},
  value: '',
  label: undefined,
  disabled: false,
  active: false,
  hideLabel: undefined,
}

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.objectOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      ).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  hideLabel: PropTypes.bool,
}

export default Select
