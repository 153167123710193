import { Label } from '@nobia/zeus-components/lib/forms'
import styled from 'styled-components'
import React from 'react'

const SelectLabel = styled(Label)`
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  height: 42px;
  padding: 0 12px;
  line-height: 42px;
  color: ${p => p.theme.forms.colors.color};
  z-index: 1;
  margin: 0;
  width: 100%;
`

const LabelSpan = styled.span`
  position: relative;
  z-index: 1;
`

const StyledBG = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${p => p.theme.forms.colors.background};
  transition: background-color, transform 0.1s linear;
  z-index: 1;
`

const StyledInput = styled.input.attrs({ type: 'radio' })`
  display: none;

  &:checked + ${StyledBG} {
    background-color: ${p => p.theme.colors.darkGrey || '#183c69'};
  }
  &:checked + ${StyledBG} + ${LabelSpan} {
    color: ${p => p.theme.forms.colors.background};
  }
`

const RadioButtonSquare = ({ name, value, label, ...rest }) => (
  <SelectLabel>
    <StyledInput name={name} value={value} {...rest} data-cs-mask />
    <StyledBG />
    <LabelSpan>{label}</LabelSpan>
  </SelectLabel>
)

RadioButtonSquare.propTypes = {
  name: '',
  value: '',
  label: '',
  width: null,
}
RadioButtonSquare.defaultProps = {
  name: '',
  value: '',
  label: '',
  width: null,
}

export default RadioButtonSquare
