import React, { Suspense, lazy, memo, useCallback, useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import settings from '@nobia/zeus-components/lib/settings'
import features from '@nobia/zeus-components/lib/features'
import styled from 'styled-components'
import LoadingIndicator from '@nobia/zeus-components/lib/loadingIndicator'
import {
  trackPageView,
  setDimension,
} from '@nobia/zeus-components/lib/analytics'
import useAuth from '@nobia/zeus-components/lib/auth/useAuth'
import { AccountProvider } from '../contexts/AccountProvider'
import ProtectedRoute from '../components/route/ProtectedRoute'
import { useRoutes } from '../routes'
import { CloudinaryProvider } from '../components/cloudinary/CloudinaryProvider'
import { LoadingOverlay } from '../components/customers/CustomerFilter'

const CustomersPage = memo(lazy(() => import('../pages/customers')))
const AccountPage = memo(lazy(() => import('../pages/account')))
const SapPage = memo(lazy(() => import('../pages/sap')))
const NotFoundPage = memo(lazy(() => import('../pages/not-found')))
const ReleaseNotesPage = memo(lazy(() => import('../pages/release-notes')))
const AppointmentsPage = memo(lazy(() => import('../pages/appointments')))
const PrepformExportPage = memo(lazy(() => import('../pages/prepform-export')))

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  background: ${p => p.theme.base.background};
  overflow-x: hidden;
`

// eslint-disable-next-line react/prop-types
const Loading = ({ children }) => (
  <Suspense
    fallback={
      <LoadingOverlay>
        <LoadingIndicator size="large" />
      </LoadingOverlay>
    }
  >
    {children}
  </Suspense>
)

export const App = () => {
  const { profile } = useAuth()
  const location = useLocation()
  const {
    customers,
    account,
    sap,
    releaseNotes,
    appointments,
    prepformExport,
  } = useRoutes()

  const setAnalyticsDimension = useCallback(() => {
    if (profile && profile.store_id) {
      setDimension('dimension12', profile.store_id)
    }
  }, [profile])

  useEffect(() => {
    setAnalyticsDimension()
    trackPageView(`${settings.basename}${location.pathname}`)
  }, [location])

  return (
    <Wrapper>
      <CloudinaryProvider>
        <AccountProvider>
          <Switch>
            <ProtectedRoute
              role="staff"
              path="/"
              render={() => <Redirect to={customers.path} />}
              exact
            />
            <ProtectedRoute
              role="staff"
              path={customers.path}
              render={() => (
                <Loading>
                  <CustomersPage />
                </Loading>
              )}
            />
            <ProtectedRoute
              role="staff"
              path={account.path}
              render={() => (
                <Loading>
                  <AccountPage />
                </Loading>
              )}
            />
            {features.sap === true && (
              <ProtectedRoute
                role="sapadmin"
                path={sap.path}
                render={() => (
                  <Loading>
                    <SapPage />
                  </Loading>
                )}
              />
            )}
            <ProtectedRoute
              role="staff"
              path={appointments.path}
              render={() => (
                <Loading>
                  <AppointmentsPage />
                </Loading>
              )}
            />
            <ProtectedRoute
              role="staff"
              path={releaseNotes.path}
              render={() => (
                <Loading>
                  <ReleaseNotesPage />
                </Loading>
              )}
            />
            <ProtectedRoute
              role="NOBG-SalesTool-ExportPrepform"
              path={prepformExport.path}
              render={() => (
                <Loading>
                  <PrepformExportPage />
                </Loading>
              )}
            />
            <Route
              path="/sso"
              exact
              render={() => {
                window.location.href = '/login?provider=azure&path=/sales-tool'
                return <></>
              }}
            />
            <ProtectedRoute
              role="staff"
              render={() => (
                <Loading>
                  <NotFoundPage />
                </Loading>
              )}
            />
          </Switch>
        </AccountProvider>
      </CloudinaryProvider>
    </Wrapper>
  )
}

export default App
