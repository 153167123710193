import { transparentize } from '@nobia/zeus-components/lib/helpers/polished'

const primary = '#31261D'
const primaryHover = 'rgba(49,38,29,0.7)'
const white = '#FBF9F6'

const tone1 = '#F2E9DB'
const tone2 = '#E4D4C4'

const lineColor = '#C3B5A7'

export default {
  grid: {
    // TODO: move to theme
    container: '1240px',
  },
  base: {
    background: tone1,
    color: primary,
    colorHover: primaryHover,
    border: `1px solid ${lineColor}`,
    icon: {
      color: primary,
    },
  },
  header: {
    title: {
      fontFamily: 'Reckless',
      fontWeight: 350,
      fontSize: 32,
    },
    breakpoint: 721,
    border: `1px solid ${lineColor}`,
    logo: {
      width: '141px',
      md: {
        width: '168px',
      },
    },
    colors: {
      primaryColor: primary,
      primaryBackground: tone1,
      secondaryColor: primary,
      secondaryBackground: tone2,
      darkGrey: primary,
      iconColor: primary,
      iconBackground: 'none',
    },
  },
  typography: {
    paragraph: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    pageTitle: {
      color: primary,
      fontFamily: 'Reckless',
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: 350,
      md: {
        fontSize: '66px',
        lineHeight: '71px',
      },
    },
    heading1: {
      fontFamily: 'Reckless',
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: 350,
      md: {
        fontSize: '66px',
        lineHeight: '71px',
      },
    },
    heading2: {
      fontFamily: 'Reckless',
      fontSize: '30px',
      lineHeight: '36px',
      fontWeight: 350,
      md: {
        fontSize: '46px',
        lineHeight: '50px',
      },
    },
    heading3: {
      fontFamily: 'Reckless',
      fontSize: '26px',
      lineHeight: '30px',
      fontWeight: 350,
      md: {
        fontSize: '32px',
        lineHeight: '38px',
      },
    },
    heading4: {
      fontFamily: 'Lausanne',
      fontSize: '18px',
      lineHeight: '24px',
      fontWeight: 650,
      md: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
    heading5: {
      fontFamily: 'Lausanne',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 650,
      md: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    heading6: {
      fontFamily: 'Lausanne',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 650,
      md: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    headline: {
      textTransform: 'none',
      letterSpacing: 0,
      color: primary,
      fontSize: '18px',
      fontSizeDesktop: '18px',
    },
    link: {
      color: primary,
    },
  },
  forms: {
    border: `1px solid ${lineColor}`,
    borderRadius: '3px',
    fontFamily: 'Lausanne',
    colors: {
      background: white,
    },
    searchIconTop: '34px',
    label: {
      color: 'red',
      fontFamily: 'Lausanne',
      fontSizeDesktop: '16px',
      fontWeight: 650,
    },
    currency: {
      symbol: {
        top: '10px',
        left: '10px',
      },
    },
  },
  smallButton: {
    borderRadius: '5px',
    fontSize: '13px',
    textTransform: 'none',
    height: '32px',
  },
  dropdownMenu: {
    background: white,
    color: primary,
    border: `1px solid ${lineColor}`,
    button: {
      borderRadius: 3,
      border: `1px solid ${lineColor}`,
      color: primary,
      fontWeight: '300',
      textTransform: 'none',
      fontSize: '14px',
    },
    item: {
      last: {
        background: tone2,
      },
    },
  },
  table: {
    header: {
      fontFamily: 'Lausanne',
      fontWeight: '650',
      fontSize: '16px',
    },
    colors: {
      headerBackground: tone2,
      background: tone1,
      activeBackground: transparentize(0.6, tone2),
    },
  },
  navbar: {
    backgroundColor: tone2,
    color: primary,
  },
  box: {
    borderRadius: '3px',
    border: `1px solid ${lineColor}`,
    colors: {
      headerBackground: tone2,
      background: white,
    },
  },
  searchFilter: {
    borderRadius: '3px',
    border: `1px solid ${lineColor}`,
    background: tone2,
  },
  status: {
    background: tone2,
  },
  customerInfo: {
    title: {
      fontFamily: 'Lausanne',
      fontWeight: 650,
      fontSize: '16px',
    },
    description: {
      fontFamily: 'Lausanne',
      fontWeight: 350,
      fontSize: '14px',
    },
  },
  message: {
    borderRadius: 0,
  },
  logEntry: {
    border: `1px solid ${lineColor}`,
  },
  filter: {
    select: {
      dropdown: {
        top: '55px',
      },
    },
  },
}
