import { Cloudinary } from '@cloudinary/url-gen'
import settings from '@nobia/zeus-components/lib/settings'
import PropTypes from 'prop-types'
import React, { createContext, useCallback, useContext, useMemo } from 'react'

const CloudinaryContext = createContext({})

export const useCloudinary = () => useContext(CloudinaryContext)

export const CloudinaryProvider = ({ children }) => {
  const cloudinary = useMemo(
    () =>
      new Cloudinary({
        cloud: {
          cloudName: settings.cloudinary.cloudName,
        },
      }),
    []
  )

  const imageUrl = useCallback(
    (url, resizeFn) => {
      if (!url) {
        return ''
      }

      const publicId = url.startsWith('https://marbodalintegration')
        ? `marbodalintegration/${settings.env}${new URL(url).pathname}`
        : url
      const deliveryType = publicId.startsWith('http') ? 'fetch' : 'upload'

      return cloudinary
        .image(publicId)
        .setDeliveryType(deliveryType)
        .resize(resizeFn)
        .quality('auto')
        .format('jpg')
        .toURL()
    },
    [cloudinary]
  )

  const value = useMemo(() => ({ cloudinary, imageUrl }), [
    cloudinary,
    imageUrl,
  ])

  return (
    <CloudinaryContext.Provider value={value}>
      {children}
    </CloudinaryContext.Provider>
  )
}

CloudinaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
