import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useIntl } from '@nobia/zeus-components/lib/i18n'
import Label from './Label'

const Field = styled.input`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 120px;
  height: ${p => p.theme.forms.height};
  outline: none;
  border: ${p => p.theme.forms.border};
  padding: ${p => p.theme.forms.padding};
  padding-bottom: 0;
  padding-top: 0;
  font-family: ${p => p.theme.forms.fontFamily};
  font-weight: ${p => p.theme.forms.fontWeight};
  line-height: ${p => p.theme.forms.height};
  color: ${p => p.theme.forms.colors.color};
  background-color: ${p => p.theme.forms.colors.background};
  z-index: 1;
  border-radius: ${p => p.theme.forms.borderRadius};
  margin-bottom: ${props => props.marginBottom};
  font-size: ${p => p.theme.forms.fontSize};
  border-color: ${p => !!p.error && p.theme.colors.red};
`

const FieldWrapper = styled.div`
  position: relative;
`

const InputField = ({ label, children, ...props }) => {
  const intl = useIntl()

  const suffix = useMemo(() => {
    if (props.required === true) {
      return '*'
    }
    if (props.required === false) {
      return intl.formatMessage({ id: 'pages.account.optional' })
    }
    return ''
  }, [props.required])
  return (
    <>
      {label && (
        <Label>
          {label} {suffix}
        </Label>
      )}
      <FieldWrapper>
        {children}
        <Field {...props} />
      </FieldWrapper>
    </>
  )
}

InputField.propTypes = {
  label: PropTypes.string,
  required: PropTypes.boolean,
  children: PropTypes.node,
}

InputField.defaultProps = {
  label: null,
  required: null,
  children: null,
}

export default InputField
