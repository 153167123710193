import { useTranslation } from '@nobia/zeus-components/lib/i18n'
// eslint-disable-next-line no-unused-vars
import React, { useCallback, useMemo } from 'react'

export const useRoutes = () => {
  const { t } = useTranslation('app.nav')

  const calculatePath = useCallback((path, params) => {
    const parsedPath = path
      .split('/')
      .filter(Boolean)
      .map(segment => {
        if (!segment.startsWith(':')) return segment
        const paramKey = segment.slice(1)
        return params[paramKey]
      })
      .join('/')

    return `/${parsedPath}`
  }, [])

  const routes = useMemo(
    () => ({
      sap: {
        title: t('sap.title'),
        path: t('sap.path'),
        calculatePath: params => calculatePath(t('sap.path'), params),
      },
      sapRequest: {
        title: t('sap.request.title'),
        path: t('sap.request.path'),
        calculatePath: params => calculatePath(t('sap.request.path'), params),
      },
      account: {
        title: t('account.title'),
        path: t('account.path'),
        calculatePath: params => calculatePath(t('account.path'), params),
      },
      releaseNotes: {
        title: t('release-notes.title'),
        path: t('release-notes.path'),
        calculatePath: params => calculatePath(t('release-notes.path'), params),
      },
      appointments: {
        title: t('appointments.title'),
        path: t('appointments.path'),
        calculatePath: params => calculatePath(t('appointments.path'), params),
      },
      appointmentDetail: {
        title: t('appointments.appointment.title'),
        path: t('appointments.appointment.path'),
        calculatePath: params =>
          calculatePath(t('appointments.appointment.path'), params),
      },
      customers: {
        title: t('customers.title'),
        path: t('customers.path'),
        calculatePath: params => calculatePath(t('customers.path'), params),
      },
      customerDetail: {
        title: t('customers.customer.title'),
        path: t('customers.customer.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.path'), params),
      },
      customerCreate: {
        title: t('customers.customer.create.title'),
        path: t('customers.customer.create.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.create.path'), params),
      },
      customerEdit: {
        title: t('customers.customer.edit.title'),
        path: t('customers.customer.edit.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.edit.path'), params),
      },
      customerAssignStore: {
        title: t('customers.customer.assign-store.title'),
        path: t('customers.customer.assign-store.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.assign-store.path'), params),
      },
      customerCreateQuote: {
        title: t('customers.customer.quote.title'),
        path: t('customers.customer.quote.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.quote.path'), params),
      },
      customerEditQuote: {
        title: t('customers.customer.quote.edit.title'),
        path: t('customers.customer.quote.edit.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.quote.edit.path'), params),
      },
      customerEditOrder: {
        title: t('customers.customer.order.edit.title'),
        path: t('customers.customer.order.edit.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.order.edit.path'), params),
      },
      customerErrand: {
        title: t('customers.customer.errand.edit.title'),
        path: t('customers.customer.errand.edit.path'),
        calculatePath: params =>
          calculatePath(t('customers.customer.errand.edit.path'), params),
      },
      prepformExport: {
        title: t('prepform-export.title'),
        path: t('prepform-export.path'),
        calculatePath: params =>
          calculatePath(t('prepform-export.path'), params),
      },
    }),
    [t, calculatePath]
  )

  return routes
}
