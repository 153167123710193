import { gql, useMutation } from '@apollo/client'
import { useTranslation } from '@nobia/zeus-components/lib/i18n/useTranslation'
import settings from '@nobia/zeus-components/lib/settings'
import styled from 'styled-components'
import { useAuth } from '@nobia/zeus-components/lib/auth'
import React, { useEffect, useState } from 'react'
import { useAccount } from '../../contexts/AccountProvider'
import { validateEmail } from '../../utils/validation'
import InputField from '../form/InputField'
import RadioGroup from '../form/RadioGroup'
import { AdaptiveTextArea } from '../form/TextArea'

const Wrapper = styled.div`
  z-index: 9999;
  position: fixed;
  right: 0;
  bottom: 50px;
  color: ${p => p.theme.header.colors.primaryColor};
  background-color: ${p => p.theme.header.colors.primaryBackground};
  padding: 20px 30px 25px;
  border-radius: 10px 0 0 10px;
  max-width: 350px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  ${p =>
    !p.showFeedback &&
    `
        padding: 20px 30px;
        max-width: 80vh;
        transform: rotate(-90deg) translateX(100%);
        transform-origin: right bottom;
        border-radius: 10px 10px 0 0;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
        border-top: 1px solid transparent;
        border-right: 1px solid transparent;
        border-left: 1px solid transparent;
        ${!p.showThankYou &&
          `:hover {
            color: #000;
            background-color: #fff;
            border-top: 1px solid #000;
            border-right: 1px solid #000;
            border-left: 1px solid #000;
        }`}
    `}
`
const Title = styled.label`
  font-family: ${p => p.theme.forms.fontFamily};
  font-weight: 800;
  font-size: 18px;
  margin-right: 10px;
`
const Label = styled.label`
  font-family: ${p => p.theme.forms.fontFamily};
  font-size: 15px;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
`
const TextButton = styled.button`
  background-color: inherit;
  border: none;
  font-size: 16px;
  font-family: ${p => p.theme.forms.fontFamily};
  padding: 0;
  cursor: pointer;
  position: relative;
`
const CloseButton = styled(TextButton)`
  position: absolute;
  top: 0px;
  right: 10px;
  font-size: 30px;
`
const Description = styled.p``
const Button = styled.button`
  align-items: center;
  background-color: #cc9c74;
  border: 1px solid #cc9c74;
  color: #fff;
  border-radius: 5px;
  box-shadow: none;
  display: inline-block;
  display: inline-flex;
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0;
  line-height: 22px;
  outline: 0;
  margin-top: 5px;
  padding: 11px 30px 13px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  transition: opacity 0.1s ease;
  z-index: 0;
  cursor: pointer;
  width: 100%;

  :hover {
    opacity: 0.85;
  }
`
const TextArea = styled(AdaptiveTextArea)`
  max-height: 60vh;
  overflow-y: scroll;
  border-radius: 5px;
`
const Row = styled.div`
  margin-top: 10px;
`
const SubmitRow = styled(Row)`
  margin-top: 15px;
  min-height: 55px;
`
const Error = styled.p`
  margin: 3px 0 8px 2px;
  color: red;
`
const Group = styled.div`
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
`

// const Arrow = styled(Button)`
//   position: absolute;
//   width: 5px;
//   height: 5px;
//   padding: 8px;
//   background-color: inherit;
// `

const capitalize = string => string.charAt(0).toUpperCase() + string.slice(1)

const SendFeedbackMutation = gql`
  mutation SendFeedback($input: FeedbackFromStaffInput!) {
    store {
      sendFeedbackFromStaff(input: $input)
    }
  }
`

const Feedback = () => {
  const { t } = useTranslation('app.feedback')
  const { store } = useAccount()
  const [error, setError] = useState('')
  const [showSubmit, setShowSubmit] = useState(false)
  const [showThankYou, setShowThankYou] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [showNext, setShowNext] = useState(false)
  const [sendFeedback] = useMutation(SendFeedbackMutation)
  const { profile } = useAuth()

  const OPTIONS = [
    { value: 'feature', label: t('feature') },
    { value: 'bug', label: t('bug') },
    { value: 'other', label: t('other') },
  ]

  const [selectedValue, setSelectedValue] = useState(OPTIONS[2].value)
  const [feedbackText, setFeedbackText] = useState('')
  const [email, setEmail] = useState(
    profile.provider === 'azure' ? profile.email : ''
  )
  const [name, setName] = useState(
    profile.provider === 'azure' ? profile.name : ''
  )

  const handleClose = () => {
    if (showNext) {
      setShowThankYou(true)
    }
    setShowFeedback(false)
    setShowNext(false)
    setSelectedValue(OPTIONS[0].value)
    setFeedbackText('')
    setEmail('')
    setShowSubmit(false)

    if (feedbackText?.length === 0) return

    sendFeedback({
      variables: {
        input: {
          feedback: {
            type: capitalize(selectedValue),
            text: feedbackText,
          },
          user: {
            email,
            name,
            store,
            brand: capitalize(settings.brand),
          },
        },
      },
    })
      .then(response => {
        // eslint-disable-next-line
        console.log({ response })
      })
      .catch(e => {
        // eslint-disable-next-line no-console
        console.error(e)
      })
  }

  const handleEmailChange = e => {
    if (validateEmail(e.target.value)) {
      setError('')
    }
    setEmail(e.target.value)
  }

  const handleSubmit = () => {
    const validEmail = validateEmail(email)
    if (feedbackText?.length < 5) {
      setError(t('error.too-short'))
      document.getElementById('text-area').focus()
      return
    }
    if (showNext && email.length && !validEmail) {
      setError(t('error.invalid-email'))
      document.getElementById('email').focus()
      return
    }
    setShowNext(true)
    if (showNext) {
      handleClose()
    }
  }

  const onChangeOption = value => {
    setSelectedValue(value)
  }

  useEffect(() => {
    if (!showThankYou) return
    setTimeout(() => {
      setName('')
      setShowThankYou(false)
    }, 4000)
  }, [showThankYou])

  useEffect(() => {
    if (!showNext) return
    setTimeout(() => {
      setShowSubmit(true)
    }, 2000)
  }, [showNext])

  return (
    <Wrapper
      showFeedback={showFeedback}
      showThankYou={showThankYou}
      onClick={() => !showThankYou && !showFeedback && setShowFeedback(true)}
    >
      {showFeedback && (
        <>
          <CloseButton onClick={handleClose}>&times;</CloseButton>
          <Title>{t('title')}</Title>
        </>
      )}
      {showFeedback && !showNext && (
        <>
          <Description>{t('description')}</Description>
          <TextArea
            id="text-area"
            onChange={e => {
              setFeedbackText(e.target.value)
              if (e.target.value.length > 5) {
                setError('')
              }
            }}
          />
          {error && <Error>{error}</Error>}
          {feedbackText && <Button onClick={handleSubmit}>{t('next')}</Button>}
        </>
      )}
      {showFeedback && showNext && (
        <>
          <Description>{t('more-info')}</Description>
          <Label>{t('type')}</Label>
          <Group>
            <RadioGroup
              options={OPTIONS}
              value={selectedValue}
              name="feedback type"
              onChange={onChangeOption}
            />
          </Group>
          {profile.provider !== 'azure' && (
            <>
              <Row>
                <InputField
                  id="email"
                  label={t('email')}
                  value={email}
                  onChange={handleEmailChange}
                />
              </Row>
              {error && <Error>{error}</Error>}
              <Row>
                <InputField
                  id="name"
                  label={t('name')}
                  value={name}
                  onChange={e => setName(e.target.value)}
                />
              </Row>
            </>
          )}
          <SubmitRow>
            {showSubmit && <Button onClick={handleSubmit}>{t('send')}</Button>}
          </SubmitRow>
        </>
      )}
      {!showFeedback && (
        <TextButton showThankYou={showThankYou}>
          {showThankYou
            ? `${t('thank-you')}${name ? ` ${name}` : ''}!`
            : t('title')}
          {/* <Arrow>&times;</Arrow> */}
        </TextButton>
      )}
    </Wrapper>
  )
}

export default Feedback
