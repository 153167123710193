import { getTrackerField } from '@nobia/zeus-components/lib/analytics'
import { useAuth } from '@nobia/zeus-components/lib/auth'
import queryString from '@nobia/zeus-components/lib/helpers/queryString'
import settings from '@nobia/zeus-components/lib/settings'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import Feedback from '../feedback/Feedback'

const Box = styled.div`
  margin: 32px;
  display: flex;
  flex-direction: column;
`

const LogoutBox = styled.div`
  margin-top: 8px;
`

function ProtectedRoute({ role, ...props }) {
  const { status, profile } = useAuth()
  const loginPath = useMemo(() => {
    const query = queryString.stringify({
      ...queryString.parse(getTrackerField('linkerParam')),
      ReturnUrl: location.pathname,
      forced: true,
      ...(settings.login.query || {}),
    })
    return `${settings.login.path}?${query}`
  }, [])

  if (status === 'unauthenticated') {
    window.location.href = loginPath
    return <></>
  }

  const roles = Array.isArray(profile?.roles) ? profile.roles : [profile.roles]

  if (roles.includes(role)) {
    return <Route {...props} />
  }

  return (
    <Box>
      <p>You are not allowed to access this page. Missing role {role}</p>
      {!roles.includes('customer') && (
        <>
          <p>
            Use the feedback button to contact us if you expect to have access
            to Sales Tool
          </p>
          <Feedback />
        </>
      )}
      <span>Email: {profile?.email}</span>
      <span>Username: {profile?.username}</span>
      <span>Roles: {roles.length > 1 ? roles?.join(', ') : roles}</span>
      <LogoutBox>
        <a href={settings.logout.path}>Logout</a>
      </LogoutBox>
      <LogoutBox>
        <a href={loginPath}>Login</a>
      </LogoutBox>
    </Box>
  )
}

ProtectedRoute.propTypes = {
  role: PropTypes.string.isRequired,
}

export default ProtectedRoute
