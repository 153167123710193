// eslint-disable-next-line no-unused-vars
import React, { useCallback, useMemo } from 'react'
import { useQuery } from '@apollo/client'

import listStaffMembersForStoreQuery from '../../graphql/StaffMembersForStore.graphql'

export const useStaffMembers = storeId => {
  const { loading, data } = useQuery(listStaffMembersForStoreQuery, {
    variables: { storeId: `${storeId}` },
  })

  const sortByName = useCallback((a, b) => {
    const aName = `${a.firstName} ${a.lastName}`
    const bName = `${b.firstName} ${b.lastName}`
    if (aName > bName) {
      return 1
    } else if (bName > aName) {
      return -1
    }

    return 0
  }, [])

  const sorted = useMemo(
    () => [...(data?.staffMember?.staffMembers || [])].sort(sortByName),
    [data, sortByName]
  )

  return { loading, staffMembers: sorted }
}
