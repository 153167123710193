import React, { useState, useRef, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { CheckBox } from '@nobia/zeus-components/lib/forms'

const Icon = styled.span`
  position: absolute;
  display: block;
  top: 13px;
  right: 18px;
  z-index: 5;
  pointer-events: none;
  font-size: 16px;
  line-height: 16px;
`

const Container = styled.div`
  position: relative;
  display: block;
  z-index: 5;
`

const Header = styled.div`
  appearance: none;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 120px;
  height: ${p => p.theme.forms.height};
  outline: none;
  border: ${p => p.theme.forms.border};
  border-radius: ${p => p.theme.forms.borderRadius};
  padding: ${p => p.theme.forms.padding};
  padding-top: 0;
  padding-bottom: 0;
  font-family: ${p => p.theme.forms.fontFamily};
  font-size: ${p => p.theme.forms.fontSize};
  font-weight: ${p => p.theme.forms.fontWeight};
  line-height: ${p => p.theme.forms.height};

  color: ${p => p.theme.forms.colors.color};
  background-color: ${p => p.theme.forms.colors.background};
  z-index: 1;

  cursor: pointer;

  ${p => p.theme.media.min.sm`
    font-size: ${p.theme.forms.fontSizeDesktop};
  `};

  opacity: ${p => !!p.disabled && 0.6};
  ${p =>
    !!p.active &&
    css`
      border-color: ${p.theme.forms.colors.activeBorder};
      color: ${p.theme.forms.colors.active};
    `};
`

const Filters = styled.div`
  display: ${p => !p.active && 'none'};
  position: absolute;
  top: ${p => p.theme.filter.select.dropdown.top};
  box-sizing: border-box;
  width: 100%;
  color: ${p => p.theme.forms.colors.active};
  border: 1px solid ${p => p.theme.forms.colors.activeBorder};
  border-radius: 5px;
  font-family: ${p => p.theme.forms.fontFamily};
  font-size: ${p => p.theme.forms.fontSize};
  background-color: ${p => p.theme.forms.colors.background};
  z-index: 5;
  margin-top: -2px;

  & > span > label {
    ${p => p.theme.media.min.sm`
      font-size: ${p.theme.forms.fontSizeDesktop};
    `};
  }
`

const CheckboxSpan = styled.span`
  display: flex;
  align-items: center;
  padding: 8px 10px 0px;
  background-color: ${p => !!p.selected && p.theme.colors.backgroundSecondary};

  cursor: pointer;
  & > label {
    cursor: pointer;
  }
  &:hover {
    background-color: ${p => p.theme.colors.backgroundSecondary};

    &:first-of-type {
      border-radius: 5px 5px 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 5px 5px;
    }
  }
`

const FilterSelect = ({ onChange, options, title, disabled, ...rest }) => {
  const [isOpen, setIsOpen] = useState(false)
  const containerRef = useRef()

  const onClickItem = (e, { value, checked }) => {
    e.preventDefault()
    onChange(value, !checked)
  }

  const activeOptionsCount = useMemo(
    () => options.filter(o => o.checked).length,
    [options]
  )

  const onHeaderClick = useCallback(() => {
    if (disabled) {
      return
    }
    setIsOpen(o => !o)
  }, [disabled])

  const onBlur = useCallback(() => {
    setIsOpen(false)
  }, [])

  const isSelected = useCallback(
    value => options.some(o => o.checked && o.value === value),
    [options]
  )

  return (
    <Container ref={containerRef} {...rest} onBlur={onBlur}>
      <Header disabled={disabled} active={isOpen} onClick={onHeaderClick}>
        {title} ({activeOptionsCount})<Icon>{isOpen ? '–' : '+'}</Icon>
      </Header>
      <Filters active={isOpen}>
        {options.map(({ value, label, checked }, index) => (
          <CheckboxSpan
            key={value}
            selected={isSelected(value)}
            onClick={e => onClickItem(e, { index, value, checked })}
          >
            <CheckBox
              value={value}
              name={value}
              label={label}
              checked={checked}
              readOnly
            />
          </CheckboxSpan>
        ))}
      </Filters>
    </Container>
  )
}

FilterSelect.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

FilterSelect.defaultProps = {
  disabled: false,
}

export { FilterSelect }
