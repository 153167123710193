// @flow
import PropTypes from 'prop-types'
import styled from 'styled-components'
import React from 'react'
import RadioButtonSquare from './RadioButtonSquare'

const RadioGroupWrapper = styled.div`
  display: flex;
  border-radius: ${p => p.theme.forms.borderRadius};
  overflow: hidden;
  label {
    user-select: none;
    cursor: pointer;
    font-weight: 300;
    padding: 0 10px 0px 10px;
    border: 0px !important;
    :first-of-type {
      border-radius: ${p => p.theme.forms.borderRadius} 0px 0px
        ${p => p.theme.forms.borderRadius} !important;
      span {
        border-radius: ${p => p.theme.forms.borderRadius} 0px 0px
          ${p => p.theme.forms.borderRadius};
      }
    }
    :last-of-type {
      border-radius: 0px ${p => p.theme.forms.borderRadius}
        ${p => p.theme.forms.borderRadius} 0px !important;
      span {
        border-radius: 0px ${p => p.theme.forms.borderRadius}
          ${p => p.theme.forms.borderRadius} 0px;
      }
    }
  }
`

const RadioGroup = ({ name, options, type, onChange, value }) => (
  <RadioGroupWrapper type={type}>
    {options.map(item => (
      <RadioButtonSquare
        key={item.value}
        name={name}
        value={item.value}
        label={item.label}
        checked={item.value === value}
        onChange={e => onChange(e.target.value)}
      />
    ))}
  </RadioGroupWrapper>
)

RadioGroup.defaultProps = {
  name: '',
  type: '',
  options: '',
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape()),
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
}

export default RadioGroup
