// eslint-disable-next-line no-unused-vars
import React, { useCallback, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { useAuth } from '@nobia/zeus-components/lib/auth'

const allStoresQuery = gql`
  query stores($includeFakeStores: Boolean) {
    store {
      id
      stores(includeFakeStores: $includeFakeStores) {
        id
        storeName
        storeManagerId
      }
    }
  }
`

export const useStores = () => {
  const { profile } = useAuth()
  if (!profile?.roles?.includes('staff')) return { loading: true, stores: [] }

  const { loading, data } = useQuery(allStoresQuery, {
    variables: {
      includeFakeStores: Array.isArray(profile?.roles)
        ? !!profile?.roles.find(r => r === 'fake-stores')
        : profile?.roles === 'fake-stores',
    },
  })

  const sortByName = useCallback((a, b) => {
    const compare = a.storeName.localeCompare(b.storeName)
    if (compare > 0) {
      return 1
    } else if (compare < 0) {
      return -1
    }

    return 0
  }, [])

  const sortedStores = useMemo(
    () => [...(data?.store?.stores || [])].sort(sortByName),
    [data, sortByName]
  )

  return { loading, stores: sortedStores }
}
