import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@nobia/zeus-components/lib/icons'
import styled from 'styled-components'

const Container = styled.div`
  border-radius: 30px;
  width: 70px;
  height: 29px;
  display: flex;
  background-color: ${p =>
    p.enabled ? p.theme.colors.green : p.theme.colors.darkGrey};
  justify-content: space-between;
  transition: background-color 300ms ease;
  cursor: pointer;
`
const ControlIcon = styled(Icon)`
  width: 21px;
  height: 21px;
  margin-top: 3px;
  margin-left: 8px;
  margin-right: 8px;
  transition: transform 300ms ease;
  transform: ${({ enabled }) =>
    enabled ? 'translateX(0)' : 'translateX(165%)'};
  visibility: ${p => !!p.hide && 'hidden'};
`

const Control = styled.div`
  width: 21px;
  height: 21px;
  background-color: #ffffff;
  margin-top: 4px;
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 50%;
  border: 1px solid ${({ enabled }) => (enabled ? '#559f54' : '#b3b6c0')};
  transition: transform 300ms ease;
  transform: ${({ enabled }) =>
    enabled ? 'translateX(0)' : 'translateX(-185%)'};
`

const Slider = ({ onEnable, onDisable, enabled, hideIcon }) => (
  <Container
    enabled={enabled ? 1 : 0}
    onClick={() => (enabled ? onDisable() : onEnable())}
  >
    <ControlIcon
      hide={hideIcon}
      enabled={enabled ? 1 : 0}
      type={enabled ? 'eye' : 'eye-disabled'}
      width={20}
      height={20}
    />
    <Control enabled={enabled ? 1 : 0} />
  </Container>
)

Slider.propTypes = {
  enabled: PropTypes.bool,
  hideIcon: PropTypes.bool,
  onEnable: PropTypes.func,
  onDisable: PropTypes.func,
}

Slider.defaultProps = {
  enabled: null,
  hideIcon: false,
  onEnable: PropTypes.func,
  onDisable: PropTypes.func,
}

export default Slider
