import { transparentize } from '@nobia/zeus-components/lib/helpers/polished'
import getTheme from '@nobia/zeus-components/lib/themes/getTheme'

const theme = getTheme('hthdk')

const primaryColor = '#111111'
const secondaryColor = '#FFFFFF'

const tone1 = '#F7EFE9'
const tone2 = '#F2F2F2'

const lineColor = '#D6D6D6'

export default {
  grid: {
    // TODO: move to theme
    container: '1240px',
  },
  base: {
    background: theme.colors.white,
    color: primaryColor,
    colorHover: '#888',
    border: `1px solid ${lineColor}`,
    icon: {
      color: primaryColor,
    },
  },
  header: {
    title: {
      fontWeight: 700,
      fontSize: '23px',
    },
    breakpoint: 721,
    border: `1px solid ${lineColor}`,
    logo: {
      width: '37px',
      md: {
        width: '58px',
      },
    },
    colors: {
      primaryColor: '#111111',
      primaryBackground: secondaryColor,
      secondaryColor: primaryColor,
      secondaryBackground: secondaryColor,
      iconColor: primaryColor,
      iconBackground: 'none',
    },
  },
  typography: {
    pageTitle: {
      color: primaryColor,
    },
    headline: {
      textTransform: 'none',
      letterSpacing: 0,
      color: primaryColor,
      fontSize: '18px',
      fontSizeDesktop: '18px',
    },
    link: {
      color: primaryColor,
    },
    heading1: {
      fontFamily: 'Clan',
      fontSize: '30px',
      lineHeight: '38px',
      fontWeight: 700,
      md: {
        fontSize: '42px',
        lineHeight: '48px',
      },
    },
    heading2: {
      fontFamily: 'Clan',
      fontSize: '2px',
      lineHeight: '28px',
      fontWeight: 700,
      md: {
        fontSize: '32px',
        lineHeight: '38px',
      },
    },
    heading3: {
      fontFamily: 'Clan',
      fontSize: '20px',
      lineHeight: '26px',
      fontWeight: 700,
      md: {
        fontSize: '26px',
        lineHeight: '34px',
      },
    },
    heading4: {
      fontFamily: 'Clan',
      fontSize: '18px',
      lineHeight: '26px',
      fontWeight: 700,
      md: {
        fontSize: '18px',
        lineHeight: '26px',
      },
    },
    heading5: {
      fontFamily: 'Clan',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 700,
      md: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    heading6: {
      fontFamily: 'Clan',
      fontSize: '15px',
      lineHeight: '22px',
      fontWeight: 700,
      md: {
        fontSize: '15px',
        lineHeight: '22px',
      },
    },
    byline: {
      fontSize: '13px',
      color: '#999999',
      fontWeight: 200,
    },
  },
  forms: {
    borderRadius: '25px',
    border: `1px solid ${lineColor}`,
    searchIconTop: '34px',
    fontFamily: 'Clan',
    label: {
      color: 'red',
      fontFamily: 'Clan',
      fontSizeDesktop: '15px',
      fontWeight: 500,
    },
    currency: {
      left: '60px',
      symbol: {
        top: '14px',
        left: '20px',
      },
    },
  },
  button: {
    padding: '0.9528125rem 2.25rem',
    checkmark: {
      icon: {
        top: '-2px',
      },
    },
  },
  smallButton: {
    borderRadius: '25px',
    fontSize: '11px',
    textTransform: 'uppercase',
    height: '34px',
  },
  dropdownMenu: {
    background: '#ffffff',
    color: primaryColor,
    border: `1px solid ${lineColor}`,
    button: {
      borderRadius: '25px',
      border: `1.5px solid ${primaryColor}`,
      color: primaryColor,
      fontWeight: '700',
      textTransform: 'uppercase',
      fontSize: '11px',
    },
    item: {
      last: {
        background: tone1,
      },
    },
  },
  table: {
    colors: {
      headerBackground: tone1,
      background: theme.colors.backgroundPrimary,
      activeBackground: transparentize(0.6, tone1),
    },
  },
  navbar: {
    backgroundColor: secondaryColor,
    color: primaryColor,
  },
  box: {
    borderRadius: '10px',
    border: `1px solid ${lineColor}`,
    colors: {
      headerBackground: tone1,
      background: theme.colors.backgroundPrimary,
    },
  },
  searchFilter: {
    borderRadius: '25px',
    border: `1px solid ${lineColor}`,
    background: tone1,
  },
  status: {
    background: tone1,
  },
  message: {
    borderRadius: '6px',
  },
  logEntry: {
    border: `1px solid ${lineColor}`,
  },
  note: {
    background: tone2,
  },
  filter: {
    select: {
      dropdown: {
        top: '55px',
      },
    },
  },
}
