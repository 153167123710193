import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useTranslation } from '@nobia/zeus-components/lib/i18n/useTranslation'
import settings from '@nobia/zeus-components/lib/settings'
import { Select } from '../form'
import { FilterSelect } from '../filter'
import { isMessagingEnabled } from '../../utils/storeUtils'
import Slider from '../../components/form/Slider'
import Label from '../form/Label'

const Item = styled.div``

const SliderWrapper = styled.div`
  height: 42px;
  display: flex;
  align-items: center;
`

export const LoadingOverlay = styled.div`
  margin-top: 30px;
  height: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f1f4fd;
  opacity: 0.7;
`

const CustomerFilter = ({
  input,
  staffMembers = [],
  taskOptions,
  onShowDeadLeadsChange,
  onOptionsChange,
  onFilterChange,
}) => {
  const { t } = useTranslation('pages.customers.filter')

  const handleSortingChange = useCallback(
    e => {
      if (e.target.value === '') {
        onOptionsChange()
      } else {
        onOptionsChange(...e.target.value.split('-'))
      }
    },
    [onOptionsChange]
  )
  const handleStaffChange = useCallback(
    e => {
      if (e.target.value === '') {
        onFilterChange('staffId')
      } else {
        onFilterChange('staffId', e.target.value)
      }
    },
    [onFilterChange]
  )
  const handleTaskChange = useCallback(
    (task, checked) => onFilterChange(task, checked),
    [onFilterChange]
  )

  const { storeId, staffId } = input?.filter
  const sorting = useMemo(
    () => `${input?.options?.field}-${input?.options?.order}`,
    [input]
  )

  const messaging = useMemo(
    () =>
      isMessagingEnabled(storeId) && settings.store.multiple !== true
        ? [
            {
              value: 'UNREAD_CUSTOMER_MESSAGES-DESC',
              label: t('sort.unread-customer-messages-desc'),
            },
          ]
        : [],
    []
  )
  const sortingOptions = useMemo(
    () => [
      ...messaging,
      {
        value: 'NAME-ASC',
        label: t('sort.name-asc'),
      },
      {
        value: 'NAME-DESC',
        label: t('sort.name-desc'),
      },
      {
        value: 'EMAIL-ASC',
        label: t('sort.email-asc'),
      },
      {
        value: 'EMAIL-DESC',
        label: t('sort.email-desc'),
      },
      {
        value: 'LAST_ACTION_DATE-ASC',
        label: t('sort.active-asc'),
      },
      {
        value: 'LAST_ACTION_DATE-DESC',
        label: t('sort.active-desc'),
      },
    ],
    [messaging]
  )

  const staffOptions = useMemo(
    () => [
      {
        value: '',
        label: t('staff.all'),
      },
      ...staffMembers.map(staff => ({
        value: staff.id,
        label: `${staff.firstName} ${staff.lastName}`,
      })),
    ],
    [staffMembers, t]
  )

  return (
    <>
      <Item>
        <Label>{t('tasks.title')}</Label>
        <FilterSelect
          tabIndex={3}
          title={t('filter.title')}
          options={taskOptions}
          onChange={handleTaskChange}
        />
      </Item>
      <Item>
        <Label>{t('sort.title')}</Label>
        <Select
          tabIndex={4}
          name="sorting"
          options={sortingOptions}
          value={sorting}
          onChange={handleSortingChange}
        />
      </Item>
      <Item>
        <Label>{t('staff.title')}</Label>
        <Select
          tabIndex={2}
          name="staff"
          options={staffOptions}
          value={staffId}
          onChange={handleStaffChange}
          disabled={staffMembers == null || storeId === ''}
        />
      </Item>
      <Item>
        <Label>{t('show-inactive.title')}</Label>
        <SliderWrapper>
          <Slider
            hideIcon
            enabled={input.filter.showInactive}
            onEnable={() => onShowDeadLeadsChange(true)}
            onDisable={() => onShowDeadLeadsChange(false)}
          />
        </SliderWrapper>
      </Item>
    </>
  )
}

CustomerFilter.propTypes = {
  input: PropTypes.shape({
    searchString: PropTypes.string.isRequired,
    filter: PropTypes.shape({
      storeId: PropTypes.string,
      activeStatusesSalesTool: PropTypes.arrayOf(PropTypes.string),
      showInactive: PropTypes.bool,
    }).isRequired,
    options: PropTypes.shape({
      field: PropTypes.string.isRequired,
      order: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  staffMembers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      firstName: PropTypes.string.isRequired,
      lastName: PropTypes.string.isRequired,
    })
  ),
  taskOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onShowDeadLeadsChange: PropTypes.func.isRequired,
  onOptionsChange: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
}

CustomerFilter.defaultProps = {
  staffMembers: null,
  loadingCustomer: false,
}

export default CustomerFilter
