import styled from 'styled-components'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef } from 'react'

const Field = styled.textarea`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 120px;
  outline: none;
  border: ${p => p.theme.forms.border};
  padding: ${p => p.theme.forms.padding};
  font-family: ${p => p.theme.forms.fontFamily};
  font-weight: ${p => p.theme.forms.fontWeight};
  line-height: 1.3;
  color: ${p => p.theme.forms.colors.color};
  background-color: ${p => p.theme.forms.colors.background};
  z-index: 1;
  border-radius: ${p => p.theme.forms.borderRadius};
  margin-bottom: ${props => props.marginBottom};
  font-size: ${p =>
    p.inputFontSize ? p.inputFontSize : p.theme.forms.fontSize};
  resize: none;
`

const Label = styled.label`
  font-size: 15px;
  font-weight: 400;
  display: block;
  margin-bottom: 5px;
`

export const AdaptiveTextArea = ({ onChange, ...props }) => {
  const label = props.label
  const ref = useRef()

  const textareaChange = useCallback(
    e => {
      if (e) {
        onChange(e)
      }
      if (!ref.current) {
        return
      }
      ref.current.style.height = 'auto'
      ref.current.style.height = `${ref.current.scrollHeight + 2}px`
    },
    [ref]
  )

  useEffect(() => {
    textareaChange()
  }, [])

  return (
    <>
      {label && <Label>{label}</Label>}
      <Field ref={ref} onChange={e => textareaChange(e)} rows={1} {...props} />
    </>
  )
}

AdaptiveTextArea.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
}

AdaptiveTextArea.defaultProps = {
  label: null,
  onChange: () => {},
}
