import PropTypes from 'prop-types'
import LoadingIndicator from '@nobia/zeus-components/lib/loadingIndicator'
import React, { useMemo } from 'react'
import styled from 'styled-components'
import { useStores } from './useStores'

const Loading = styled.div`
  display: inline-block;
`

export const StoreName = ({ storeId }) => {
  const { stores, loading } = useStores()
  const name = useMemo(
    () => stores?.find(s => `${s.id}` === `${storeId}`)?.storeName,
    [stores, storeId]
  )
  return loading ? (
    <Loading>
      <LoadingIndicator size="small" />
    </Loading>
  ) : (
    <>{name || 'N/A'}</>
  )
}

StoreName.propTypes = {
  storeId: PropTypes.string.isRequired,
}
