import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import LoadingIndicator from '@nobia/zeus-components/lib/loadingIndicator'
import styled from 'styled-components'
import { useStaffMembers } from './useStaffMembers'

const Loading = styled.div`
  display: inline-block;
`

export const StaffMemberName = ({ storeId, staffId }) => {
  const { loading, staffMembers } = useStaffMembers(storeId)
  const staffName = useMemo(() => {
    const staff = staffMembers?.find(s => `${s.id}` === `${staffId}`)
    return staff ? `${staff.firstName} ${staff.lastName}` : ''
  }, [staffMembers, staffId])

  return loading ? (
    <Loading>
      <LoadingIndicator size="small" />
    </Loading>
  ) : (
    <>{staffName || 'N/A'}</>
  )
}

StaffMemberName.propTypes = {
  staffId: PropTypes.number.isRequired,
  storeId: PropTypes.number.isRequired,
}
