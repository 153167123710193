import features from '@nobia/zeus-components/lib/features'
import settings from '@nobia/zeus-components/lib/settings'

export const getCustomerStatus = customer => {
  if (settings.store.multiple === true) {
    return customer?.journey?.statusString
  }
  return customer?.projectState?.activeStatusSalesTool
}

/**
 * @param {string} storeId
 * @returns {boolean}
 */
export const isMessagingEnabled = storeId =>
  features.messaging === true &&
  (settings.messaging?.storeIds?.includes(+storeId) ||
    settings.messaging?.storeIds?.length === 0)
