import { getTheme } from '@nobia/zeus-components/lib/themes'

const theme = getTheme('magnet')

const primaryColor = '#001c32'
const secondaryColor = '#f1f4fd'

const lineColor = '#e0e4f1'

export default {
  grid: {
    // TODO: move to theme
    container: '1240px',
  },
  base: {
    background: theme.colors.white,
    color: primaryColor,
    colorHover: '#888',
    border: `1px solid ${lineColor}`,
    icon: {
      color: '#b2b6bc',
    },
  },
  header: {
    title: {
      fontWeight: 700,
      fontSize: '23px',
    },
    breakpoint: 721,
    logo: {
      width: '79px',
      md: {
        width: '100px',
      },
    },
    colors: {
      primaryColor: theme.colors.white,
      primaryBackground: primaryColor,
      secondaryColor: theme.colors.white,
      secondaryBackground: primaryColor,
      iconColor: theme.colors.white,
      iconBackground: 'none',
    },
  },
  typography: {
    link: {
      color: '#002b5e',
    },
    pageTitle: {
      color: primaryColor,
      fontFamily: 'Poppins',
      fontSize: '36px',
      lineHeight: '40px',
      fontWeight: 650,
      md: {
        fontSize: '36px',
        lineHeight: '40px',
      },
    },
    heading4: {
      fontFamily: 'NewSpirit',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 500,
      md: {
        fontSize: '21px',
        lineHeight: '27px',
      },
    },
    heading5: {
      fontFamily: 'NewSpirit',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 500,
      md: {
        fontSize: '18px',
        lineHeight: '24px',
      },
    },
    heading6: {
      fontFamily: 'NewSpirit',
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 500,
      md: {
        fontSize: '16px',
        lineHeight: '22px',
      },
    },
    byline: {
      fontSize: '13px',
      color: '#627584',
      fontWeight: 200,
    },
  },
  forms: {
    height: '42px',
    border: `1px solid ${lineColor}`,
    borderRadius: '5px',
    fontFamily: 'Poppins',
    searchIconTop: '32px',
    colors: {
      background: theme.colors.white,
    },
    currency: {
      left: '23px',
      symbol: {
        top: '10px',
        left: '10px',
      },
    },
  },
  button: {
    fontSize: '15px',
    padding: '1.0714285714285714rem 3.2142857142857144rem',
    checkmark: {
      icon: {
        top: '0px',
      },
    },
  },
  smallButton: {
    borderRadius: '5px',
    fontSize: '14px',
    textTransform: 'none',
    height: '29px',
  },
  progressIcon: {
    complete: {
      background: theme.colors.green,
    },
    incomplete: {
      background: theme.colors.darkGrey,
    },
  },
  dropdownMenu: {
    background: '#ffffff',
    color: primaryColor,
    border: '1px solid #e0e4f1',
    button: {
      borderRadius: '20px',
      border: '1.5px solid #fff',
      activeBackground: 'rgba(255, 255, 255, 0.1)',
      color: '#fff',
      fontWeight: '300',
      textTransform: 'none',
      fontSize: '14px',
    },
    item: {
      last: {
        background: '#f6faff',
      },
    },
  },
  navbar: {
    backgroundColor: theme.colors.white,
    color: theme.colors.primary,
  },
  table: {
    colors: {
      headerBackground: secondaryColor,
      background: theme.colors.backgroundPrimary,
      activeBackground: '#f6fafe',
    },
  },
  box: {
    borderRadius: '10px',
    border: '1px solid #e0e4f1',
    colors: {
      headerBackground: secondaryColor,
      background: theme.colors.backgroundPrimary,
    },
  },
  searchFilter: {
    borderRadius: '10px',
    border: '1px solid #e0e4f1',
    background: secondaryColor,
  },
  status: {
    background: '#ebeef7',
  },
  message: {
    borderRadius: '6px',
  },
  logEntry: {
    border: '2px solid #f1f4fd',
  },
  note: {
    background: '#d4ddf6',
  },
  filter: {
    select: {
      dropdown: {
        top: '55px',
      },
    },
  },
}
