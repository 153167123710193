import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Icon } from '@nobia/zeus-components/lib/icons'
import Label from '../form/Label'

const FilterLabel = styled.label`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  padding: 10px 15px;
  color: ${p => p.theme.typography.link.color};
  border: ${p => p.theme.forms.border};
  border-radius: ${p => p.theme.forms.borderRadius};
  user-select: none;
  cursor: pointer;
`

const WideDivider = styled.hr`
  height: 1px;
  border: 0;
  background-color: #e0e3f1;
  width: 100%;
`

const StyledLabel = styled(Label)`
  margin: 0 16px 0 0;
`

const PositionedColumn = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const FilterLabels = ({ options, onChange }) => {
  const items = useMemo(() => options.filter(item => item.checked), [options])

  if (!items.length) {
    return <></>
  }

  return (
    <>
      <WideDivider />
      <PositionedColumn>
        <StyledLabel>Active filters</StyledLabel>
        {items.map(item => (
          <FilterLabel
            key={item.value}
            onClick={() => onChange(item.value, false)}
          >
            {item.label}
            <Icon type="close" width={9} height={9} />
          </FilterLabel>
        ))}
      </PositionedColumn>
    </>
  )
}

FilterLabels.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      checked: PropTypes.bool.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
}

export { FilterLabels }
