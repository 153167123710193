import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const toCurrency = (number, locale, currencyName) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'decimal',
    currency: currencyName,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  if (!number) return ''
  return formatter.format(number.toString())
}

const dispatchValid = (setInternalValue, onChange, e) => {
  const value = e.target.value
  const regex = /[^0-9.,]/
  if (
    (!regex.test(value) &&
      isNaN(value) === false &&
      parseFloat(value) < 1000000) ||
    value === ''
  ) {
    setInternalValue(value)
    onChange(e)
  }
}

const Container = styled.div`
  position: relative;
`

const StyledInputField = styled.input`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  min-width: 120px;
  height: ${p => p.theme.forms.height};
  outline: none;
  border: ${p => p.theme.forms.border};
  padding: ${p => p.theme.forms.padding};
  padding-top: 0;
  padding-bottom: 0;
  font-family: ${p => p.theme.forms.fontFamily};
  font-weight: ${p => p.theme.forms.fontWeight};
  line-height: ${p => p.theme.forms.height};
  color: ${p => p.theme.forms.colors.color};
  background-color: ${p => p.theme.forms.colors.background};
  z-index: 1;
  border-radius: ${p => p.theme.forms.borderRadius};
  margin-bottom: ${p => p.marginBottom};
  font-size: ${p =>
    p.inputFontSize ? p.inputFontSize : p.theme.forms.fontSize};
  padding-left: ${p => p.theme.forms.currency.left};
`

const CurrencySymbol = styled.span`
  position: absolute;
  top: ${p => p.theme.forms.currency.symbol.top};
  left: ${p => p.theme.forms.currency.symbol.left};
  z-index: 1;
  font-size: 16px;
`
const CurrencyInputField = ({
  name,
  value,
  currencyName,
  currencySymbol,
  locale,
  onChange,
}) => {
  const [isEditing, setIsEditing] = useState(false)
  const [internalValue, setInternalValue] = useState(value)
  const inputRef = useCallback(
    node => {
      let end = 0
      if (internalValue) {
        end = internalValue.length
      }
      if (node) {
        node.setSelectionRange(end, end)
      }
    },
    [internalValue]
  )

  return (
    <Container>
      {isEditing ? (
        <StyledInputField
          type="text"
          name={name}
          value={internalValue}
          onChange={e => dispatchValid(setInternalValue, onChange, e)}
          onBlur={() => setIsEditing(prevValue => !prevValue)}
          ref={inputRef}
        />
      ) : (
        <StyledInputField
          type="text"
          name={name}
          value={toCurrency(value, locale, currencyName)}
          onFocus={() => setIsEditing(prevValue => !prevValue)}
        />
      )}
      <CurrencySymbol>{currencySymbol}</CurrencySymbol>
    </Container>
  )
}

CurrencyInputField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  currencySymbol: PropTypes.string.isRequired,
  currencyName: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
}

export default CurrencyInputField
