import useAuth from '@nobia/zeus-components/lib/auth/useAuth'
import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStores } from '../components/store'

const initialSelectedStore = JSON.parse(
  localStorage.getItem('sales-tool-store')
)

const AccountContext = React.createContext({
  store: initialSelectedStore,
  stores: [],
  loading: false,
})

export const withAccount = Component => props => (
  <AccountContext.Consumer>
    {context => <Component {...props} account={context} />}
  </AccountContext.Consumer>
)

export const useAccount = () => React.useContext(AccountContext)

export const AccountProvider = props => {
  const { profile } = useAuth()
  const [selectedStore, setSelectedStore] = React.useState(initialSelectedStore)
  const { stores: queryStores, loading: loadingStores } = useStores()

  const sortStore = useCallback((a, b) => {
    if (a.label === b.label) {
      return 0
    }
    return a.label < b.label ? -1 : 1
  }, [])

  const filterStores = useCallback(
    userStoreIds =>
      (userStoreIds || [])
        .map(id => +id)
        .map(id => ({
          id: `${id}`,
          label: queryStores.find(s => s.id === id)?.storeName,
        }))
        .filter(s => s.label)
        .sort(sortStore),
    [sortStore, queryStores]
  )

  const { stores, loading } = useMemo(() => {
    if (loadingStores) {
      return { stores: [], loading: true }
    }
    if (queryStores && profile) {
      const admin = profile?.roles?.includes('sales-tool-admin') || false
      const profileIds =
        typeof profile.store_ids === 'string'
          ? [profile.store_ids]
          : profile.store_ids
      const adminIds = admin ? queryStores.map(s => s.id) : []
      const ids = admin ? adminIds : profileIds
      return { stores: filterStores(ids), loading: false }
    }
    return { stores: [], loading: false }
  }, [profile, loadingStores, queryStores])

  useEffect(() => {
    if (!stores || stores.length === 0) {
      return
    }
    const selected = stores.find(s => s.id === selectedStore?.id)
    if (!selectedStore || !selected) {
      setSelectedStore({ id: stores[0].id, label: stores[0].label })
    }
  }, [stores])

  const setStore = useCallback(
    storeId => {
      if (!profile || !storeId) {
        return
      }
      const sameStore = selectedStore?.id === storeId
      if (sameStore) {
        return
      }

      const inStoresList = stores.map(s => +s.id).indexOf(+storeId) >= 0
      if (inStoresList) {
        const storeName = stores.find(s => s.id === `${storeId}`)?.label
        setSelectedStore({ id: `${storeId}`, label: storeName })
      }
    },
    [profile, selectedStore, stores]
  )

  useEffect(() => {
    localStorage.setItem('sales-tool-store', JSON.stringify(selectedStore))
  }, [selectedStore])

  const value = useMemo(
    () => ({
      store: selectedStore,
      stores,
      loading,
      setStore,
    }),
    [selectedStore, stores, loading, setStore]
  )

  return (
    <AccountContext.Provider value={value}>
      {props.children}
    </AccountContext.Provider>
  )
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
