const settings = require('@nobia/zeus-components/lib/settings')

const isNumber = str => /^\d+$/.test(str)

const validationRules = {
  noConsecutiveDots: value => {
    const noConsecutiveDots = value.indexOf('..') === -1
    return noConsecutiveDots
  },
  noDotsEnding: value => {
    const noDotsEnd = value[value.length - 1] !== '.'
    return noDotsEnd
  },
  noNumericEnding: value => {
    const noNumericEnd = !isNumber(value[value.length - 1])
    return noNumericEnd
  },
  noSpaces: value => {
    const noSpaces = value.indexOf(' ') === -1
    return noSpaces
  },
  noDisallowedDomains: value => {
    const domains = settings.default.disallowedDomains
    if (!domains) {
      return true
    }
    const groups = value.match(/^([a-zA-Z0-9-._+]+)([@]{1})([a-zA-Z0-9-.+]+)$/)
    if (groups && groups.length === 4) {
      return (
        domains.find(d => d === groups[3].toLowerCase()) === undefined || false
      )
    }
    return false
  },
}

const validateEmail = value => {
  const trimmedValue = value.trim()
  const patternValid = /^([a-zA-Z0-9-._+]+)([@]{1})([a-zA-Z0-9-.]+)([.]{1})([a-zA-Z0-9]+)$/.test(
    trimmedValue
  )

  const rulesValid = Object.values(validationRules).every(validation =>
    validation(trimmedValue)
  )

  return patternValid && rulesValid
}

const validateName = value => {
  const trimmedValue = value.trim()
  const names = trimmedValue.split(' ')
  const isValid = names.length > 1 && names.every(name => name.length > 0)
  return isValid
}

const validateAddress = value => {
  if (!value || (value && value.length === 0)) {
    return false
  }

  const trimmedValue = value.trim()
  return (trimmedValue && trimmedValue.length > 0) || false
}

exports.validateEmail = validateEmail
exports.validateName = validateName
exports.validateAddress = validateAddress
