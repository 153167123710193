import { getTheme } from '@nobia/zeus-components/lib/themes'

const theme = getTheme('base')

export default {
  app: {
    header: {
      breakpoint: 721,
      background: theme.colors.black,
      color: theme.colors.white,
    },
  },
}
